function Equipment() {
  const scrollTo = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="Equipment">
      <div className="relative h-80 md:h-[448px]">
        <img
          className="mx-auto h-full w-full object-cover"
          src="/images/equipment-banner.jpg"f
          alt="Drilling Equipment Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white ">
          Equipment
        </h1>
      </div>

      <div className="grid-container relative !px-0">
        <div className="col-span-full md:col-start-1 md:col-span-2 md:mr-3 sticky top-[120px] md:top-[100px] lg:top-[120px] md:h-[calc(100vh-120px)] bg-black md:border-solid md:border-y md:border-white">
          <div className="md:mt-4">
            <h3
              className="hover:underline py-4 md:py-2 cursor-pointer text-white"
              onClick={() => scrollTo("rig1")}
            >
              Rig 1
            </h3>

            <h3
              className="hover:underline py-4 md:py-2 cursor-pointer text-white"
              onClick={() => scrollTo("rig2")}
            >
              Rig 2
            </h3>
          </div>
        </div>
        <div className="col-span-4 md:col-span-10 col-start-1 md:col-start-3">
          <div
            id="rig1"
            className="px-5 md:px-0"
          >
            <div className="py-8">
              <h2 className="pb-3 text-black text-center font-bold">
                Rig 1 - 2022 Hanjin D&B 8D
              </h2>

              <div className="md:grid md:grid-cols-2 mt-10">
                <div>
                  <div className="max-w-sm mx-auto text-left">
                    <p className="pb-2 ">
                      Rig 1 is a powerful and versatile multipurpose rig.  With
                      rubber tracks it can access the most difficult sites with
                      very little footprint.
                    </p>
                    <a className="underline hover:font-bold" href="https://haddad-drilling-website-documents.s3.ap-southeast-2.amazonaws.com/DR01+Specs.pdf" target="_blank" rel="noreferrer">Download Rig 1 Specs Here</a>
                  </div>

                  <table className="mt-10 mx-auto">
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          className="bg-black text-white text-center font-semibold"
                        >
                          Drilling Rig Capabilities
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Solid Augers
                        </td>
                        <td>25m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Hollow flight augers
                        </td>
                        <td>20m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          SPT Testing
                        </td>
                        <td>Yes – Automatic hammer</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          U75 & U50 sampling
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Rotary Mud
                        </td>
                        <td>150m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Rotary Air
                        </td>
                        <td>150m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Concrete Coring
                        </td>
                        <td>50-250mm</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          NMLC
                        </td>
                        <td>100m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          HMLC
                        </td>
                        <td>75m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          NQ
                        </td>
                        <td>250m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          HQ
                        </td>
                        <td>150m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          PQ
                        </td>
                        <td>100m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          HQ & HWT Casing
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Advancer
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Angle Holes
                        </td>
                        <td>Up to 45 degrees</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="mt-10 mx-auto">
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          className="bg-black text-white text-center font-semibold"
                        >
                          Rig Dimensions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Width
                        </td>
                        <td>2.5m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Length{" "}
                        </td>
                        <td>
                          8m (Mast down) <br />
                          4.5m (Mast up)
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Height
                        </td>
                        <td>
                          2.5m (Mast down) <br />
                          8m (Mast up)
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Weight
                        </td>
                        <td>7000kg</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="mt-10 mx-auto">
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          className="bg-black text-white text-center font-semibold"
                        >
                          Safety
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Emergency Stop
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Spill Kit
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          First Aid Kit
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Fire Extinguisher
                        </td>
                        <td>4.5kg</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Service History
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Safety Cage
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Hydraulic Make & Break
                        </td>
                        <td>Yes</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-10 flex flex-col justify-around">
                  <img
                    className="mx-auto md:px-16 py-10 md:py-0"
                    src="/images/drill-rig-1.jpg"
                    alt="Rig 1 - 2022 Hanjin D&B 8D angle 1"
                  />

                  <img
                    className="mx-auto md:px-16 py-10 md:py-0"
                    src="/images/drill-rig-3.jpg"
                    alt="Rig 1 - 2022 Hanjin D&B 8D angle 2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            id="rig2"
            className="px-5 md:px-0"
          >
            <div className="py-8">
              <h2 className="pb-3 text-black text-center font-bold">
                Rig 2 - Hanjin D&B 8D Truck Mounted Drill Rig
              </h2>

              <div className="md:grid md:grid-cols-2 mt-10">
                <div>
                  <div className="max-w-sm mx-auto text-left">
                    <p className="pb-2 ">
                      Rig 2 is a multi purpose rig with the ability to Air Core/RC, RAB, Diamond, Auger and Mud Rotary. Mounted on a 4x4 truck this rig is ideal for any site investigation. 
                    </p>
                    <a className="underline hover:font-bold" href="https://haddad-drilling-website-documents.s3.ap-southeast-2.amazonaws.com/DR02+Specs.pdf" target="_blank" rel="noreferrer">Download Rig 2 Specs Here</a>
                  </div>

                  <table className="mt-10 mx-auto">
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          className="bg-black text-white text-center font-semibold"
                        >
                          Drilling Rig Capabilities
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Solid Augers
                        </td>
                        <td>25m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Hollow flight augers
                        </td>
                        <td>20m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          SPT Testing
                        </td>
                        <td>Yes – Automatic hammer</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          U75 & U50 sampling
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Rotary Mud
                        </td>
                        <td>150m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Rotary Air
                        </td>
                        <td>150m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Concrete Coring
                        </td>
                        <td>50-250mm</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          NMLC
                        </td>
                        <td>100m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          HMLC
                        </td>
                        <td>75m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          NQ
                        </td>
                        <td>250m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          HQ
                        </td>
                        <td>150m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          PQ
                        </td>
                        <td>100m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          HQ & HWT Casing
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Advancer
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Angle Holes
                        </td>
                        <td>Up to 45 degrees</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="mt-10 mx-auto">
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          className="bg-black text-white text-center font-semibold"
                        >
                          Rig Dimensions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Width
                        </td>
                        <td>2.3m</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Length
                        </td>
                        <td>
                          7.5m
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Height
                        </td>
                        <td>
                          3.6m (Mast down) <br />
                          6.5m (Mast up)
                        </td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Weight
                        </td>
                        <td>10000kg</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="mt-10 mx-auto">
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          className="bg-black text-white text-center font-semibold"
                        >
                          Safety
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Emergency Stop
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Spill Kit
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          First Aid Kit
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Fire Extinguisher
                        </td>
                        <td>9kg</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Service History
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Safety Cage
                        </td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td className="bg-primary text-black font-semibold">
                          Hydraulic Make & Break
                        </td>
                        <td>Yes</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-10 flex flex-col justify-around">
                  <img
                    className="mx-auto md:px-16 py-5 md:py-0"
                    src="/images/rig2-angle1.jpg"
                    alt="Rig 2 - Hanjin D&B 8D Truck Mounted Drill Rig angle 1"
                  />

                  <img
                    className="mx-auto md:px-16 py-10 md:py-0"
                    src="/images/rig2-angle2.jpg"
                    alt="Rig 1 - Hanjin D&B 8D Truck Mounted Drill Rig angle 2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Equipment;
